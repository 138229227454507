import React from 'react';

import dynamic from 'next/dynamic';

import clsx from 'clsx';

import styles from './Brands.module.css';

const BrandsLight = dynamic(() => import('./BrandsLight'), {
    ssr: true,
});

const BrandsDark = dynamic(() => import('./BrandsDark'), {
    ssr: true,
});

const BrandsWhite = dynamic(() => import('./BrandsWhite'), {
    ssr: true,
});

interface BrandsProps {
    colorVersion?: 'dark' | 'white' | false;
    lazy?: boolean;
    fetchpriority?: boolean;
    title?: JSX.Element;
    className?: string;
}

function Brands({
    colorVersion = false,
    lazy = true,
    fetchpriority = false,
    title,
    className,
}: React.PropsWithChildren<BrandsProps>): JSX.Element {
    return (
        <div className={clsx(styles.brands, colorVersion === 'dark' ? styles.dark : '', className)}>
            {title ? title : null}
            {colorVersion === 'white' && (
                <BrandsWhite
                    lazy={lazy}
                    fetchpriority={fetchpriority}
                />
            )}
            {colorVersion === 'dark' && (
                <BrandsDark
                    lazy={lazy}
                    fetchpriority={fetchpriority}
                />
            )}
            {colorVersion === false && (
                <BrandsLight
                    lazy={lazy}
                    fetchpriority={fetchpriority}
                />
            )}
        </div>
    );
}

export default Brands;
