import React, { cloneElement } from 'react';

import { useRouter } from 'next/router';

import Trans from '@components/Trans';
import { Button } from '@components/ui/design2023/Button';

import useTranslation from '@hooks/useTranslation';
import { laravelRouteIntegrations } from '@static_components/laravelLinks';

import styles from './Integrations.module.css';
import integration_AVIF from './assets/integration@1x.avif';
import integration from './assets/integration@1x.png';
import integration_x2_AVIF from './assets/integration@2x.avif';
import integration_x2 from './assets/integration@2x.png';

const Content = () => {
    const { t } = useTranslation('pages/homepage/2024/v1/index');
    const { locale } = useRouter();

    return (
        <div className={styles.columns}>
            <div className={styles['col-1']}>
                <h2 className={styles.header}>
                    <Trans
                        i18nKey="integrationsHeader"
                        components={{
                            u: <span />,
                        }}
                        ns="pages/homepage/2024/v1/index"
                    />
                </h2>
                <p>{t('integrationsText')}</p>
                <Button
                    label={t('integrationsButton')}
                    href={laravelRouteIntegrations(locale)}
                    size="medium"
                    color="black"
                />
            </div>
            <div className={styles['col-2']}>
                <figure className={styles.picture}>
                    <picture>
                        <source
                            type="image/avif"
                            srcSet={`${integration_AVIF.src} 1x, ${integration_x2_AVIF.src} 2x`}
                        />
                        <source srcSet={`${integration.src} 1x, ${integration_x2.src} 2x`} />
                        <img
                            width={integration.width}
                            height={integration.height}
                            decoding="async"
                            loading="lazy"
                            alt=""
                            src={integration_x2.src}
                        />
                    </picture>
                </figure>
            </div>
        </div>
    );
};

interface IntegrationsProps {
    container: React.ReactElement;
}

export default function Integrations({ container }: IntegrationsProps): JSX.Element {
    return (
        <section className={styles.integrations}>
            {cloneElement(container, {
                children: <Content />,
            })}
        </section>
    );
}
