import * as React from 'react';

import getConfig from 'next/config';
import Head from 'next/head';
import { useRouter } from 'next/router';

import useTranslation from '@hooks/useTranslation';
import {
    laravelRouteAboutContactUs,
    laravelRouteFeaturesSupport,
    laravelRouteSearch,
} from '@static_components/laravelLinks';

const { publicRuntimeConfig } = getConfig();

export default function JsonLdWrapper() {
    const { t } = useTranslation('pages/homepage/2024/v1/index');
    const { locale } = useRouter();
    const proto = publicRuntimeConfig.ssl ? 'https://' : 'http://';
    const homepageUrl =
        proto +
        publicRuntimeConfig.domains[locale].domain +
        (publicRuntimeConfig.domains[locale].prefix ? publicRuntimeConfig.domains[locale].prefix : '');
    const website = {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        url: homepageUrl,
        potentialAction: {
            '@type': 'SearchAction',
            target: {
                '@type': 'EntryPoint',
                urlTemplate:
                    laravelRouteSearch(locale) +
                    '?query={search_term_string}&utm_source=google&utm_medium=organic&utm_campaign=sitelinks-searchbox',
            },
            'query-input': 'required name=search_term_string',
        },
    };
    const organization = {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        name: 'GetResponse',
        legalName: 'GetResponse S.A',
        slogan: t('seo.title'),
        description: t('seo.description'),
        foundingDate: '1998',
        url: homepageUrl,
        logo: 'https://upload.wikimedia.org/wikipedia/commons/2/2c/GetResponse_Logo.png',
        awards: 'https://www.getresponse.com/about/press-center#awards',
        vatID: 'EU 9581468984',
        areaServed: 'Worldwide',
        address: {
            '@type': 'PostalAddress',
            streetAddress: 'Aleja Grunwaldzka 413',
            addressLocality: 'Gdańsk',
            addressRegion: 'PM',
            addressCountry: 'PL',
            postalCode: '80-309',
        },
        founder: {
            '@type': 'Person',
            name: 'Szymon Grabowski',
            alternateName: 'Simon Grabowski',
            sameAs: [
                'https://www.wikidata.org/wiki/Q105547127',
                'https://www.linkedin.com/in/grabowski',
                'https://twitter.com/simongrabowski',
                'https://www.crunchbase.com/person/simon-grabowski',
            ],
        },
        contactPoint: [
            {
                '@type': 'ContactPoint',
                contactType: 'customer service',
                email: 'info@getresponse.com',
                areaServed: 'Worldwide',
                availableLanguage: ['en', 'pl', 'ru', 'de', 'fr', 'es', 'it', 'pt'],
                url: laravelRouteFeaturesSupport(locale),
            },
            {
                '@type': 'ContactPoint',
                contactType: 'sales',
                email: 'sales@getresponse.com',
                areaServed: 'Worldwide',
                url: laravelRouteAboutContactUs(locale),
            },
            {
                '@type': 'ContactPoint',
                contactType: 'marketing',
                email: 'marketing@getresponse.com',
                areaServed: 'Worldwide',
                url: laravelRouteAboutContactUs(locale),
            },
        ],
    };

    if (locale === 'pl') {
        organization.sameAs = [
            'https://twitter.com/getresponse',
            'https://www.facebook.com/GetResponse',
            'https://www.linkedin.com/company/getresponse',
            'https://www.youtube.com/user/NewGetResponse',
            'https://www.instagram.com/getresponse/',
            'https://www.google.com/maps/place/GetResponse/@54.4054535,18.5736074,17z/data=!3m1!4b1!4m5!3m4!1s0x46fda09eca4cc9fd:0x62bd626d1f6c76de!8m2!3d54.4054692!4d18.5759138',
            'https://pl.wikipedia.org/wiki/GetResponse',
        ];
    } else {
        organization.sameAs = [
            'https://twitter.com/getresponse',
            'https://www.facebook.com/GetResponse',
            'https://www.linkedin.com/company/getresponse',
            'https://www.youtube.com/user/NewGetResponse',
            'https://www.instagram.com/getresponse/',
            'https://www.google.com/maps/place/GetResponse/@54.4054535,18.5736074,17z/data=!3m1!4b1!4m5!3m4!1s0x46fda09eca4cc9fd:0x62bd626d1f6c76de!8m2!3d54.4054692!4d18.5759138',
        ];
    }

    return (
        <Head>
            <script
                id="website"
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(website),
                }}
            />
            <script
                id="organization"
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(organization),
                }}
            />
        </Head>
    );
}
