import React, { useRef, useState } from 'react';

import dynamic from 'next/dynamic';

import { usePreviewContext } from '@context/PreviewContext';

const WYSWIGEditor = dynamic(() => import('./WYSWIGEditor'), {
    ssr: false,
});

interface VwoTargetProps {
    id: string;
    components?: Array<{
        name: string;
        component: React.ReactNode;
    }>;
}

function VwoTarget({ children, id, components }: React.PropsWithChildren<VwoTargetProps>): JSX.Element {
    const { isPreview } = usePreviewContext();
    const [isEditable, setIsEditable] = useState(!!isPreview);
    const ref = useRef<HTMLElement | null>(null);

    if (!isPreview) {
        return React.createElement('vwo-target', { ref, id }, children);
    }

    const customElement = React.createElement(
        'vwo-target',
        { ref, id, contenteditable: !!isPreview && isEditable },
        children,
    );
    return (
        <>
            <WYSWIGEditor
                id={id}
                customElementRef={ref}
                components={components}
                isEditable={isEditable}
                setEditable={setIsEditable}
            />
            {customElement}
        </>
    );
}

export default VwoTarget;
