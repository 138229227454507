import React from 'react';

import clsx from 'clsx';

import styles from './TwoColumns.module.css';

export interface TwoColumnsProps {
    preName?: JSX.Element;
    name?: JSX.Element;
    description?: JSX.Element;
    link?: JSX.Element;
    img?: JSX.Element;
    position?: 'odd' | 'even';
    align?: 'center' | 'start' | 'end' | 'flex-start' | 'flex-end';
    video?: JSX.Element;
    customElement?: JSX.Element;
    className?: string;
    customStyles?: { 'video-wrapper'?: string; 'pre-name'?: string; description?: string; header?: string };
}

const TwoColumns = ({
    name,
    description,
    link,
    img,
    position = 'odd',
    align = 'center',
    video,
    customElement,
    preName,
    className,
    customStyles,
}: TwoColumnsProps) => {
    return (
        <div className={clsx(styles.columns, styles[position], styles[align], className)}>
            <div className={styles['col-1']}>
                {preName && <p className={clsx(styles['pre-name'], customStyles?.['pre-name'])}>{preName}</p>}
                <h2 className={clsx(styles.header, customStyles?.['header'])}>{name}</h2>
                <div className={clsx(styles.description, customStyles?.['description'])}>{description}</div>
                {link ? link : null}
            </div>
            <div className={styles['col-2']}>
                {img ? img : null}
                {video ? (
                    <div className={clsx(styles['video-wrapper'], customStyles?.['video-wrapper'])}>
                        <div className={styles.video}>{video}</div>
                    </div>
                ) : null}
                {customElement ? customElement : null}
            </div>
        </div>
    );
};

export default TwoColumns;
