import { useEffect, useState } from 'react';

import Trans from '@components/Trans';
import { CTAStartFreeCreateAccount } from '@components/ui/design2023/CTAStartFreeCreateAccount';

import clsx from 'clsx';

import styles from './NewYearPromoBanner.module.css';

const BREAKPOINT = 980;

function useViewportWidth() {
    const [width, setWidth] = useState<number>(0);

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return width;
}

function NewYearPromoBanner() {
    const viewportWidth = useViewportWidth();
    const isDesktop = viewportWidth > BREAKPOINT;

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'NY2025_banner_viewed', placement: 'Homepage' });
    }, []);

    const handleClick = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'NY2025_banner_clicked', placement: 'Homepage' });
    };

    return (
        <div className={styles.newYearPromoBanner}>
            <div className={styles.newYearPromoBannerTitle}>
                <Trans
                    i18nKey={isDesktop ? 'newYearPromoBannerTitleDesktop' : 'newYearPromoBannerTitleMobile'}
                    ns="pages/homepage/newyearpromobanner/index"
                    components={{
                        p: <p />,
                        br: <br />,
                        strong: <strong />,
                    }}
                />
            </div>
            <CTAStartFreeCreateAccount
                size="small"
                label={
                    <Trans
                        i18nKey="newYearPromoBannerCTA"
                        ns="pages/homepage/newyearpromobanner/index"
                    />
                }
                color="ghost"
                className={clsx(styles.button, styles.arrow)}
                onClick={handleClick}
                arrow={false}
            />
        </div>
    );
}

export default NewYearPromoBanner;
